export const usersRoles = [
  { id: 1, title: 'Администратор терминала', value: 'admin' },
  {
    id: 2,
    title: 'Водитель',
    value: 'driver',
    menuTitle: 'Тайм слоты',
    menuRouteName: 'Timeslots',
  },
  {
    id: 3,
    title: 'Менеджер',
    value: 'manager',
    menuTitle: 'Терминал',
    menuRouteName: 'Manager',
    menuIcon: 'IconControl',
  },
  {
    id: 4,
    title: 'Паркмен',
    value: 'parkman',
    menuTitle: 'Парковка',
    menuRouteName: 'Parkman',
    menuIcon: 'IconControl',
  },
  {
    id: 5,
    title: 'Начальник смены',
    value: 'supervisor',
    menuTitle: 'Наполнение буфера',
    menuRouteName: 'Supervisor',
    menuIcon: 'IconControl',
  },
]

export const usersRolesProfile = [
  {
    id: 1,
    title: 'Менеджер',
    value: 'manager',
  },
  {
    id: 2,
    title: 'Паркмен',
    value: 'parkman',
  },
  {
    id: 3,
    title: 'Начальник смены',
    value: 'supervisor',
  },
]

<script>
import { usersRoles } from '@/constants/roles'

export default {
  name: 'ProfileTableCell',
  props: {
    row: {
      type: Object,
      default: () => {},
    },
    propKey: {
      type: String,
      default: '',
    },
  },
  methods: {
    createTableCellName(createElement) {
      const { first_name, last_name } = this.row
      let content

      if (!first_name && !last_name) {
        content = 'Не определено'
      } else {
        content = `${first_name} ${last_name}`.trim()
      }

      return [createElement('div', { class: ['name'] }, [content])]
    },
    createTableCellRole(createElement) {
      const { role } = this.row
      const roleUser =
        usersRoles.filter(item => item.value === role)[0]?.title || role

      return [createElement('div', { class: ['role'] }, [roleUser])]
    },
    createTableCellPhone(createElement) {
      const { phone } = this.row

      return [createElement('div', { class: ['phone'] }, [phone])]
    },
    createTableCellEmail(createElement) {
      const { email } = this.row

      return [createElement('div', { class: ['email'] }, [email])]
    },
    createTableCellDefault(createElement, prop) {
      const content = this.row[prop] || 'Не определено'

      return [createElement('div', { class: [prop] }, [content])]
    },
  },
  render(createElement) {
    let classNames = [this.propKey]
    let renderData = [this.row[this.propKey]]

    switch (this.propKey) {
      case 'name': {
        renderData = this.createTableCellName(createElement)
        break
      }

      case 'login': {
        renderData = this.createTableCellRole(createElement)
        break
      }

      case 'phone': {
        renderData = this.createTableCellPhone(createElement)
        break
      }

      case 'email': {
        renderData = this.createTableCellEmail(createElement)
        break
      }

      default: {
        renderData = this.createTableCellDefault(createElement, this.propKey)
        break
      }
    }

    return createElement('div', { class: classNames }, renderData)
  },
}
</script>
